import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
var cardStyles = {
    position: 'relative',
    overflow: 'hidden',
};
var overlayStyles = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: 8,
    height: 200,
};
var ProjectCard = function (_a) {
    var title = _a.title, description = _a.description, image = _a.image, link = _a.link;
    return (_jsxs(Card, { style: cardStyles, children: [_jsx(CardMedia, { component: 'img', height: '450', image: image, alt: title }), _jsx(Box, { style: overlayStyles, children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: 'h5', children: title }), _jsx(Typography, { variant: 'body2', children: description })] }) })] }));
};
export default ProjectCard;
