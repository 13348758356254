import { jsx as _jsx } from "react/jsx-runtime";
import { Container, Box } from '@mui/material';
var careerDayContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 40,
};
var CareerDay = function () {
    return (_jsx(Container, { children: _jsx(Box, { style: careerDayContainerStyle }) }));
};
export default CareerDay;
