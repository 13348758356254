import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { AppBar, Button, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Box, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import theme from '../Theming/DefaultTheme';
var buttonStyle = {
    textTransform: 'none',
    color: theme.palette.text.primary,
};
var AppNavigation = function () {
    var navigate = useNavigate();
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var _a = useState(false), drawerOpen = _a[0], setDrawerOpen = _a[1];
    var handleNavigate = function (path, hash) {
        navigate(path);
        if (hash) {
            window.location.hash = hash;
        }
        setDrawerOpen(false); // Close the drawer after navigation
    };
    var toggleDrawer = function (open) { return function () {
        setDrawerOpen(open);
    }; };
    var drawer = (_jsx(Box, { sx: {
            width: 250,
            backgroundColor: 'white',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }, role: 'presentation', onClick: toggleDrawer(false), onKeyDown: toggleDrawer(false), children: _jsx(List, { children: _jsx(ListItem, { component: 'button', onClick: function () { return handleNavigate('/', 'about'); }, children: _jsx(ListItemText, { primary: 'About' }) }) }) }));
    return (_jsx(AppBar, { position: 'fixed', sx: {
            boxShadow: 'none',
            backgroundColor: theme.palette.common.black,
        }, children: _jsxs(Toolbar, { children: [_jsx(Box, { sx: { display: 'flex', alignItems: 'center', flexGrow: 1 }, children: _jsx(IconButton, { color: 'inherit', edge: 'start', onClick: function () { return handleNavigate('/', '#home'); }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/evanellison_full_logo.png', alt: 'Evan Ellison Logo', style: { height: 70 } }) }) }), isMobile ? (_jsxs(_Fragment, { children: [_jsx(IconButton, { color: 'inherit', edge: 'start', onClick: toggleDrawer(true), sx: { mr: 2, color: theme.palette.common.white }, children: _jsx(MenuIcon, {}) }), _jsx(Drawer, { anchor: 'left', open: drawerOpen, onClose: toggleDrawer(false), children: drawer })] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { color: 'inherit', style: buttonStyle, onClick: function () { return handleNavigate('/', 'about'); }, children: _jsx(Typography, { variant: 'button', style: { color: theme.palette.common.white }, children: "About" }) }), _jsx(Button, { color: 'inherit', style: buttonStyle, onClick: function () { return handleNavigate('/career-day'); }, children: _jsx(Typography, { variant: 'button', style: { color: theme.palette.common.white }, children: "Career Day" }) })] }))] }) }));
};
export default AppNavigation;
